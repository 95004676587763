var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"joy-login-container"},[_c('div',{staticClass:"login-bg"}),_c('div',{staticClass:"login-side"},[_c('div',{staticClass:"login-side-wrapper"},[_c('div',{staticClass:"joy-logo"}),_c('div',{staticClass:"login-title"},[_vm._v("航空销售管理系统")]),_c('div',{staticClass:"login-box"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [
                    { validator: _vm.handleFieldValidate } ],
                } ]),expression:"[\n                'username',\n                {\n                  rules: [\n                    { validator: handleFieldValidate },\n                  ],\n                },\n              ]"}],staticClass:"input-radius",attrs:{"placeholder":"用户名"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    { validator: _vm.handleFieldValidate } ],
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    { validator: handleFieldValidate },\n                  ],\n                },\n              ]"}],staticClass:"input-radius",attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('admin-slider-captcha',{ref:"verify",attrs:{"size":"large"},on:{"change":_vm.onCaptchVerify}})],1),_c('div',{staticClass:"form-footer"},[_c('router-link',{staticClass:"joy-link",attrs:{"to":"/register"}},[_vm._v("代理人注册")]),_c('a-button',{staticClass:"login-btn button-radius",attrs:{"loading":_vm.submitLoading,"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }