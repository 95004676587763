<template>
  <div class="joy-login-container">
    <div class="login-bg"></div>
    <div class="login-side">
      <div class="login-side-wrapper">
        <div class="joy-logo"></div>
        <div class="login-title">航空销售管理系统</div>
        <div class="login-box">
          <a-form
          class="login-form"
          :form="form"
          @submit="handleSubmit">
            <a-form-item>
              <a-input
                class="input-radius"
                placeholder="用户名"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      { validator: handleFieldValidate },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                class="input-radius"
                type="password"
                placeholder="密码"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      { validator: handleFieldValidate },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <admin-slider-captcha ref="verify" size="large" @change="onCaptchVerify">
              </admin-slider-captcha>
            </a-form-item>
            <div class="form-footer">
              <router-link class="joy-link" to="/register">代理人注册</router-link>
              <a-button :loading="submitLoading" class="login-btn button-radius" type="primary" html-type="submit">登录</a-button>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validate } from '@/utils/validate'
import { userLogin } from '@/api/user'
import { mapActions } from 'vuex'

import queryString from 'query-string'

export default {
  name: 'Login',
  components: {},
  data () {
    return {
      form: this.$form.createForm(this, { name: 'login_form' }),
      captchaToken: '',
      submitLoading: false,
      redirect: null
    }
  },
  created () {},
  mounted () {
    if (this.$route.query.redirect) {
      const redirect = decodeURI(this.$route.query.redirect)

      this.redirect = {
        path: redirect.split('?')[0],
        query: queryString.parse(redirect.split('?')[1])
      }
      this.redirectPath = redirect
    }
  },
  methods: {
    handleFieldValidate (rule, value, callback) {
      validate('login-user', this.form.getFieldsValue(), rule.fullField).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLogin(e)
        }
      })
    },
    submitLogin (e) {
      e.preventDefault()

      this.form.validateFields(async (errors, values) => {
        if (!errors) {
          try {
            const { captchaToken } = this

            if (!captchaToken) {
              this.$modalError({
                content: '请拖动滑块完成验证！',
                centered: true
              })
              return
            }

            this.submitLoading = true

            const params = {
              requestId: captchaToken,
              username: values.username,
              password: values.password
            }

            const data = await userLogin(params)

            await this.$store.dispatch('user/setToken', data.token)
            await this.$store.dispatch('user/setUserData', data.user)
            if (this.$store.state.user.token) {
              this.fetchFlightCities()
            }

            const userData = data.user

            if (this.redirect) {
              if (userData.type === 0) {
                location.href = this.redirectPath
              } else {
                const isAdminPath = this.redirectPath.indexOf('/admin') === 0
                this.$router.push(isAdminPath ? { path: '/' } : this.redirect)
              }
            } else {
              if (userData.type === 0) {
                location.href = '/admin/'
              } else {
                this.$router.push({ path: '/' })
              }
            }
          } catch (err) {
            this.$modalError({
              content: err.message,
              centered: true
            })
            this.submitLoading = false
          }
        }
      })
    },
    onCaptchVerify (value) {
      this.captchaToken = value
    },
    ...mapActions([
      'fetchFlightCities'
    ])
  }
}
</script>

<style lang="less" scoped>
.joy-login-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.login-bg {
  background: url("~@/assets/images/login-bg.png") no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.login-side {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 410px;
  overflow-y: auto;
  background: #f0f0f0;
  background-color: rgba(255,255,255,0.8);
  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.12);
  z-index: 100;
}
.login-side-wrapper {
  min-height: 640px;
  overflow: hidden;
}
.joy-logo {
  width: 104px;
  height: 119px;
  margin-top: 84px;
  margin-left: auto;
  margin-right: auto;
  background: url("~@/assets/images/joy-logo.png") no-repeat center;
}
.login-title {
  font-size: 18px;
  font-weight: bold;
  color: @joy-darkblue-color;
  margin: 108px auto 44px;
  text-align: center;
}
.login-box {
  padding: 0 45px;
}
.login-form {
  .input-radius {
    ::v-deep .ant-input {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border: none;
    }
  }
  .login-btn {
    width: 124px;
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.air-slider-captcha {
  background: #fff;
}
</style>
